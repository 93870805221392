<template>
  <v-container fluid class="py-16 mx-auto">
    <v-img
      :src="require('@/assets/logo.svg')"
      class="mt-0 mb-5"
      contain
      height="38"
    >
    </v-img>
    <p class="blue-grey--text text-center mb-12">
      Repository of Vocabularies and Schemas. β1.0
    </p>
    <v-card class="mx-auto" max-width="550">
      <v-card-title>Sign-in</v-card-title>
      <v-card-text>
        <v-text-field
          label="E-mail"
          hide-details="auto"
          v-model="loginMail"
        ></v-text-field>
        <v-text-field
          v-model="loginPass"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          label="Password"
          hide-details="auto"
          @click:append="show1 = !show1"
        ></v-text-field>
        <p class="mt-5 red--text">
          {{ errMsg }}
        </p>
        <p class="mt-5">
          <a href="" class="text-md-left">{{ getWords("login_forgot") }}</a>
        </p>
        <p>
          <router-link :to="{ name: 'SignUp' }" class="text-md-left">
            {{ getWords("login_registration") }}
          </router-link>
        </p>
        <div class="d-flex justify-center mt-3">
          <v-btn
            large
            outlined
            color="#5C5C5C"
            @click="handleLogin"
            :disabled="isProcessing"
            :loading="isProcessing"
          >
            {{ getWords("login_login") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fb, auth } from "../../plugins/firebase";
import words from "../../assets/localewords.json";

export default {
  name: "login",
  data: () => ({
    loginMail: "",
    loginPass: "",
    isProcessing: false,
    show1: false,
    errMsg: "",
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.login;
    },
    getLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    isLoggedIn: function (to) {
      if (to === true && this.isProcessing === true) {
        this.isProcessing = false;
        this.moveToType();
      }
    },
  },
  methods: {
    async moveToType() {
      this.$router.push({ name: "Type" }).catch((err) => console.log(err));
    },
    async handleLogin() {
      if (this.isProcessing) {
        return;
      }
      this.errMsg = "";
      this.isProcessing = true;
      await auth
        .setPersistence(fb.auth.Auth.Persistence.LOCAL)
        .then(async () => {
          return await auth
            .signInWithEmailAndPassword(this.loginMail, this.loginPass)
            .then(async () => {
              this.errMsg = "";
            })
            .catch((err) => {
              this.isProcessing = false;
              this.handleAuthError(err);
            });
        })
        .catch((err) => {
          this.isProcessing = false;
          this.handleAuthError(err);
        });
    },
    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    handleAuthError(err) {
      console.log(err);
      this.errMsg = this.getWords("login_error");
    },
  },
};
</script>

<style scoped></style>
